import React, { useEffect, useState } from 'react';
import config from '../../config';
import './Restaurants.css'; // Import your custom CSS file for styling

export default function RiderApproval() {
    const [deliveryBoys, setDeliveryBoys] = useState([]);
    const [filteredDeliveryBoys, setFilteredDeliveryBoys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchField, setSearchField] = useState('name');
    const { API_URL } = config;
    const [selectedBoy, setSelectedBoy] = useState({});
    const [pic, setPic] = useState("");

    useEffect(() => {
        fetch(`${API_URL}/api/auth/getAllDeliveryBoys`)
            .then(res => res.json())
            .then(data => {
                const unverifiedBoys = data.deliveryBoys.filter(boy => boy.status === 'unverified');
                setDeliveryBoys(unverifiedBoys);
                setFilteredDeliveryBoys(unverifiedBoys);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching unverified delivery boys:", error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (selectedBoy) {
            const images = [
                selectedBoy.idProofPic,
                selectedBoy.pancardPic,
                selectedBoy.drivingLicensePic
            ];
            images.forEach((src) => {
                if (src) {
                    const img = new Image();
                    img.src = src;
                }
            });

            setPic(selectedBoy.idProofPic);
        }
    }, [selectedBoy]);

    useEffect(() => {
        const filtered = deliveryBoys.filter(boy =>
            boy[searchField]?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDeliveryBoys(filtered);
    }, [searchTerm, searchField, deliveryBoys]);

    const handleVerifyBoy = (id) => {
        try {
            fetch(`${API_URL}/api/auth/updateDeliveryBoyById`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: id, updateData: { status: 'verified' } }),
            })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.executed) {
                    alert('Delivery boy verified successfully');
                    setLoading(true);
                    fetch(`${API_URL}/api/auth/getAllDeliveryBoys`)
                        .then(res => res.json())
                        .then(data => {
                            const unverifiedBoys = data.deliveryBoys.filter(boy => boy.status === 'unverified');
                            setDeliveryBoys(unverifiedBoys);
                            setFilteredDeliveryBoys(unverifiedBoys);
                            setLoading(false);
                        })
                        .catch(error => {
                            console.error("Error fetching unverified delivery boys:", error);
                            setLoading(false);
                        });
                } else {
                    alert('Failed to verify delivery boy');
                }
            })
        } catch (error) {
            alert('Failed to verify delivery boy', error);
        }
    };

    const handleInvalidBoy = (id) => {
        try {
            fetch(`${API_URL}/api/auth/updateDeliveryBoyById`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ _id: id, updateData: { status: 'Invalid' } }),
            })
            .then(res => res.json())
            .then(data => {
                if (data.executed) {
                    alert('Delivery boy marked as invalid successfully');
                    setLoading(true);
                    fetch(`${API_URL}/api/auth/getAllDeliveryBoys`)
                        .then(res => res.json())
                        .then(data => {
                            const unverifiedBoys = data.deliveryBoys.filter(boy => boy.status === 'unverified');
                            setDeliveryBoys(unverifiedBoys);
                            setFilteredDeliveryBoys(unverifiedBoys);
                            setLoading(false);
                        })
                        .catch(error => {
                            console.error("Error fetching unverified delivery boys:", error);
                            setLoading(false);
                        });
                } else {
                    alert('Failed to mark delivery boy as invalid');
                }
            })
        } catch (error) {
            alert('Failed to mark delivery boy as invalid', error);
        }
    };

    if (loading) {
        return (
            <div className='d-flex flex-column bg-white' style={{ minHeight: "100vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
                <div className="spinner-grow text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className='d-flex flex-column bg-white' style={{ minHeight: "100vh", maxWidth: "100vw", justifyContent: "flex-start", alignItems: "center" }}>
            <h1 className='m-5 fw-bold'>Rider Approval</h1>
            <div className="input-group mb-3 me-4" style={{ width: "50%" }}>
                <select className="form-select me-5" value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                    <option value="name">Name</option>
                    <option value="email">Email</option>
                    <option value="city">City</option>
                    <option value="contact">Contact</option>
                    <option value="firstName">First Name</option>
                    <option value="lastName">Last Name</option>
                </select>
                <input
                    type="text"
                    placeholder={`Search by ${searchField}...`}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="form-control"
                    style={{ width: "50%" }}
                />
            </div>
            <table className="table smaller-font">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Contact</th>
                        <th scope="col">City</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredDeliveryBoys.map(boy => (
                        <tr key={boy._id}>
                            <td>{boy._id}</td>
                            <td>{boy.name}</td>
                            <td>{boy.email}</td>
                            <td>{boy.contact}</td>
                            <td>{boy.city}</td>
                            <td>{boy.gender}</td>
                            <td>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                    onClick={() => {
                                        setPic("");
                                        setSelectedBoy(boy);
                                    }}
                                    className="bg-primary text-white rounded-2">Verify</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {InfoModal(selectedBoy, pic, setPic, handleVerifyBoy, handleInvalidBoy)}
        </div>
    );
}

function InfoModal(data, pic, setPic, handleVerifyBoy, handleInvalidBoy) {
    return (
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Verify {data.name}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div className='overflow-auto d-flex flex-column gap-2'>
                                <button type="button" className="bg-primary text-white rounded-2 h-fit w-fit" onClick={() => setPic(data.idProofPic)}>View ID Proof</button>
                                <button type="button" className="bg-primary text-white rounded-2 h-fit w-fit" onClick={() => setPic(data.pancardPic)}>View PAN Card</button>
                                <button type="button" className="bg-primary text-white rounded-2 h-fit w-fit" onClick={() => setPic(data.drivingLicensePic)}>View Driving License</button>
                            </div>
                            <div>
                                {pic ? (
                                    <img src={pic} height={"300px"} loading='lazy' alt="Selected" onLoad={() => console.log('Image loaded')} />
                                ) : (
                                    <div>Loading...</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => handleInvalidBoy(data._id)}>Invalid</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handleVerifyBoy(data._id)}>Verify</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
