import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import "./Restaurants.css";
import config from '../../config';

export default function AddCity() {
    const [cities, setCities] = useState([]);
    const [cityName, setCityName] = useState('');
    const [areas, setAreas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [newAreaName, setNewAreaName] = useState('');
    const [newAreaDescription, setNewAreaDescription] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedArea, setSelectedArea] = useState(null);
    const [editAreaName, setEditAreaName] = useState('');
    const [editAreaDescription, setEditAreaDescription] = useState('');
    const { API_URL } = config;

    const fetchCities = async () => {
        try {
            const response = await fetch(`${API_URL}/api/city/getAllCities`);
            const data = await response.json();
            console.log(data);
            if (data.error) {
                alert(data.error);
                setCities([]);
            } else {
                setCities(data);
            }
        } catch (error) {
            console.error("Error fetching cities:", error);
            alert("Error fetching cities");
        } finally {
            setLoading(false);
        }
    };

    const handleAddCity = async () => {
        if (cityName === '' ) {
            alert("City name cannot be empty");     //this is a comment
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/city/addCity`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    cityName: cityName.charAt(0).toUpperCase() + cityName.slice(1).toLowerCase(),
                    areas:[],
                }),
            });
            const data = await response.json();
            if (data.error) {
                alert(data.error);
            } else {
                alert("City added successfully");
                setCityName('');
                setAreas([]);
                fetchCities();
            }
        } catch (error) {
            console.error("Failed to add city", error);
            alert("Failed to add city");
        }
    };

    const handleDeleteCity = async (cityName) => {
        if (!window.confirm(`Are you sure you want to delete the city: ${cityName}?`)) return;
        try {
            const response = await fetch(`${API_URL}/api/city/deleteCity/${cityName}`, {
                method: 'DELETE',
            });
            const data = await response.json();
            if (data.error) {
                alert(data.error);
            } else {
                alert("City deleted successfully");
                fetchCities();
            }
        } catch (error) {
            console.error("Failed to delete city", error);
            alert("Failed to delete city");
        }
    };

    const handleAddArea = async () => {
        if (newAreaName === '' || newAreaDescription === '') {
            alert("Area name and description cannot be empty");
            return;
        }
        try {
            const response = await fetch(`${API_URL}/api/city/addAreaByCity/${selectedCity}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    areaName: newAreaName.charAt(0).toUpperCase() + newAreaName.slice(1).toLowerCase(),
                    description: newAreaDescription.charAt(0).toUpperCase() + newAreaDescription.slice(1).toLowerCase(),
                }),
            });
            const data = await response.json();
            if (data.error) {
                alert(data.error);
            } else {
                alert("Area added successfully");
                setNewAreaName('');
                setNewAreaDescription('');
                setSelectedCity('');
                fetchCities();
            }
        } catch (error) {
            console.error("Failed to add area", error);
            alert("Failed to add area");
        }
    };

    const handleDeleteArea = async (cityName, areaName) => {
        if (!window.confirm(`Are you sure you want to delete the area: ${areaName}?`)) return;
        try {
            const response = await fetch(`${API_URL}/api/city/deleteAreaByCity/${cityName}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    areaName: areaName,
                }),
            });
            const data = await response.json();
            if (data.error) {
                alert(data.error);
            } else {
                alert("Area deleted successfully");
                fetchCities();
            }
        } catch (error) {
            console.error("Failed to delete area", error);
            alert("Failed to delete area");
        }
    };

    const handleEditArea = async () => {
        if (editAreaName === '' || editAreaDescription === '') {
            alert("Area name and description cannot be empty");
            return;
        }
        try {
            const response = await fetch(`${API_URL}/api/city/updateDescription/${selectedCity}/${selectedArea}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                   
                    description: editAreaDescription.charAt(0).toUpperCase() + editAreaDescription.slice(1).toLowerCase(),
                }),
            });
            const data = await response.json();
            if (data.error) {
                alert(data.error);
            } else {
                alert("Area updated successfully");
                setEditAreaName('');
                setEditAreaDescription('');
                setSelectedArea(null);
                fetchCities();
            }
        } catch (error) {
            console.error("Failed to update area", error);
            alert("Failed to update area");
        }
    };

    useEffect(() => {
        fetchCities();
    }, []);

    const filteredCities = cities.filter((city) =>
        city.cityName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) {
        return (
            <div className='d-flex flex-column bg-white' style={{ minHeight: "100vh", justifyContent: "center", alignItems: "center" }}>
                <div className="spinner-grow text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className='bg-white' style={{ minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h1 className='fw-bold m-5'>City Management</h1>
            <div className='m-5 mb-3'>
                <input
                    type='text'
                    className='form-control'
                    placeholder='Search by City Name...'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <button type="button" className="btn btn-success mt-0" data-bs-toggle="modal" data-bs-target="#addCityModal">
                <FontAwesomeIcon icon={faPlusCircle} size='1x' /> Add City
            </button>

            <div className='d-flex flex-grow-1 w-100'>
                <div className="accordion p-4 w-100" id="accordionExample" style={{ maxHeight: "100%", overflowY: "auto" }}>
                {filteredCities.length === 0 ? (
                        <p>No cities found</p>
                    ) : (
                        filteredCities.map((citydata, index) => (
                            <div className="accordion-item" key={index}>
                                <h2 className="accordion-header d-flex justify-content-between align-items-center">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                        {index + 1}. {citydata.cityName}
                                    </button>
                                    <div>
                                        <button className="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#addAreaModal" onClick={() => setSelectedCity(citydata.cityName)}>
                                            Add Area
                                        </button>
                                        <button className="btn btn-danger" onClick={() => handleDeleteCity(citydata.cityName)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </div>
                                </h2>
                                <div id={`collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <ul>
                                            {citydata.areas && citydata.areas.length > 0 ? (
                                                citydata.areas.map((area, areaIndex) => (
                                                    <li key={areaIndex} className="d-flex justify-content-between align-items-center">
                                                        {area.areaName}: {area.description}
                                                        <div>
                                                            <button className="btn btn-warning btn-sm me-2" data-bs-toggle="modal" data-bs-target="#editAreaModal" onClick={() => {
                                                                setSelectedCity(citydata.cityName)
                                                                setSelectedArea(area.areaName);
                                                                setEditAreaName(area.areaName);
                                                                setEditAreaDescription(area.description);
                                                            }}>
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </button>
                                                            <button className="btn btn-danger btn-sm" onClick={() => handleDeleteArea(citydata.cityName, area.areaName)}>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </div>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No areas found for this city</li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>

            {/* Modal for Adding City */}
            <div className="modal fade" id="addCityModal" tabIndex="-1" aria-labelledby="addCityModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addCityModalLabel">Add New City</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="cityNameInput" className="form-label">City Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="cityNameInput"
                                    value={cityName}
                                    onChange={(e) => setCityName(e.target.value)}
                                />
                            </div>
                            {/* <div className="mb-3">
                                <label htmlFor="areasInput" className="form-label">Areas (format: name: description, name: description)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="areasInput"
                                    value={areas.map(area => `${area.name}: ${area.description}`).join(', ')}
                                    onChange={(e) => {
                                        const areaArray = e.target.value.split(',').map(areaStr => {
                                            const [name, description] = areaStr.split(':').map(part => part.trim());
                                            return { name, description };
                                        });
                                        setAreas(areaArray);
                                    }}
                                />
                            </div> */}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleAddCity}>Add City</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for Adding Area */}
            <div className="modal fade" id="addAreaModal" tabIndex="-1" aria-labelledby="addAreaModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAreaModalLabel">Add New Area</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="areaNameInput" className="form-label">Area Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="areaNameInput"
                                    value={newAreaName}
                                    onChange={(e) => setNewAreaName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="areaDescriptionInput" className="form-label">Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="areaDescriptionInput"
                                    value={newAreaDescription}
                                    onChange={(e) => setNewAreaDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleAddArea}>Add Area</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal for Editing Area */}
            <div className="modal fade" id="editAreaModal" tabIndex="-1" aria-labelledby="editAreaModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editAreaModalLabel">Edit Area</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="editAreaNameInput" className="form-label">Area Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled
                                    id="editAreaNameInput"
                                    value={editAreaName}
                                    onChange={(e) => setEditAreaName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="editAreaDescriptionInput" className="form-label">Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="editAreaDescriptionInput"
                                    value={editAreaDescription}
                                    onChange={(e) => setEditAreaDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleEditArea}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
